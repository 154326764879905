var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("添加任务")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.photo ? _c('img', {
      attrs: {
        "src": item.photo,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s url"
    }, [_vm._v(" " + _vm._s(item.reply) + " ")]), _c('div', {
      staticClass: "s status"
    }, [_vm._v(" " + _vm._s(item.send_type == 0 ? '全员' : '指定') + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(item.send_time_format) + " ")]), _c('div', {
      staticClass: "s status",
      class: `status_${item.status}`
    }, [_vm._v(" " + _vm._s(item.status == 0 ? '禁用' : '' + item.status == 1 ? '推送中' : '推送完成') + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.task_count) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.task_end_count) + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(_vm.cutStr(item.created_at)) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")])])]);
  })], 2), _c('div', {
    staticClass: "data-bd"
  }, [_c('my-pagination', {
    staticClass: "pagination",
    attrs: {
      "total": _vm.total,
      "page-size": _vm.condition.limit,
      "page": _vm.condition.page,
      "on-page-change": _vm.onPageChange,
      "on-page-size-change": _vm.onPageSizeChange
    }
  })], 1), _c('pop-push-task', {
    attrs: {
      "show": _vm.popTaskShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popTaskShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 描述 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" 图片 ")]), _c('div', {
    staticClass: "s url"
  }, [_vm._v(" 推送内容 ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 推送对象 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 推送时间 ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 状态 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 任务数量 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 完成数量 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 添加时间 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };