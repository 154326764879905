import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      statData: 0,
      list: [],
      isLoading: false,
      isLoading1: false,
      time: ["", ""],
      total: 0,
      condition: {
        page: 1,
        limit: 100,
        chain: '',
        symbol: ''
      },
      chainList: []
    };
  },
  methods: {
    async getData() {
      this.isLoading1 = true;
      let rs = await this.$http.post("/withdrawstat", {});
      this.isLoading1 = false;
      if (rs.code == 0) {
        this.statData = rs.data;
      }
    },
    async getDataList() {
      this.isLoading = true;
      let rs = await this.$http.post("/withdrawstat/list", {
        ...this.condition
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    async onModify(item) {
      this.modifyData = item;
      this.popTaskShow = true;
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      if (this.condition.chain == '' || this.condition.symbol == '') {
        this.$toasted.error('请选择代币');
        return false;
      }
      this.getDataList();
    },
    onPageChange(e) {
      this.condition.page = e;
      this.getData();
    },
    onPageSizeChange(e) {
      this.condition.limit = e;
      this.getData();
    },
    onDatePickerInput(val) {
      console.log("onDatePickerInput", val, this.time);
    },
    onChange(e) {
      this.condition.chain = e.split("|")[0] || '';
      this.condition.symbol = e.split("|")[1] || '';
    },
    onStat() {
      this.$router.push("/withdrawstat");
    }
  },
  async mounted() {
    this.getData();
    let chainList = [];
    let rs = await this.$http.post("/assets/chains", {});
    if (rs.code == 0) {
      chainList = rs.data;
    }
    rs = await this.$http.post("/assets/tokens", {
      chain_id: this.chain_id
    });
    if (rs.code == 0) {
      rs.data.map(item => {
        chainList.map(sitem => {
          if (!sitem.tokens) {
            sitem.tokens = [];
          }
          if (sitem.id == item.chain) {
            sitem.tokens.push(item);
          }
          return sitem;
        });
      });
    }
    this.chainList = chainList;
    this.onChange((this.chainList[0]['tokens'][0]['chain'] || '') + "|" + (this.chainList[0]['tokens'][0]['symbol'] || ''));
  }
};